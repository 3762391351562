import {
  Box,
  Flex,
  FlexProps,
  Img,
  List,
  ListItem,
  Stack,
} from '@chakra-ui/react';
import { default as NextLink } from 'next/link';
import React from 'react';
import Container from './Container';
import { MenuFragmentFragment, MenuItem } from '../../generated/types';
import format from 'date-fns/format';
import getAppPublicPath from '../../utils/getAppPublicPath';
import HeaderLogo from '../Common/HeaderLogo';
import LazyLoad from 'react-lazyload';
import { useStore } from '../../store/provider';
import getAppConfig from '../../utils/getAppConfig';

export enum FooterStyle {
  invisible,
}

export type EbikeAtlasFooterProps = FlexProps & {
  menu: MenuFragmentFragment;
  menuMain: MenuFragmentFragment;
};

function renderLink(item: MenuItem) {
  if (item.url === '') {
    return <Box>{item.title}</Box>;
  }

  return (
    <NextLink href={item.url} passHref>
      <a>
        <Box as="span">{item.title}</Box>
      </a>
    </NextLink>
  );
}

const EbikeAtlasFooter: React.FC<EbikeAtlasFooterProps> = ({
  menu,
  menuMain,
  ...props
}) => {
  const store = useStore();
  const appConfig = getAppConfig();

  const openCookieSettings = () => {
    const w = window as any;
    w?.CookieScript?.instance?.show();
  };

  return (
    <Flex
      as="footer"
      color="#fff"
      pos="relative"
      flexDirection="column"
      overflow="hidden"
      {...props}
    >
      <LazyLoad>
        <Img
          zIndex={3}
          maxHeight="356px"
          width="100%"
          htmlHeight={356}
          htmlWidth={1920}
          src={`${getAppPublicPath()}/footer-top.png`}
        />
      </LazyLoad>
      {store.config.newLayout?.footer?.addons === 'waves' && (
        <>
          <LazyLoad>
            <Img
              zIndex={3}
              pos="absolute"
              width="37%"
              left={0}
              bottom={0}
              htmlHeight={266}
              htmlWidth={715}
              src={`${getAppPublicPath()}/footer-left.png`}
            />
          </LazyLoad>
          <LazyLoad>
            <Img
              zIndex={4}
              pos="absolute"
              width="25%"
              right={0}
              top="20%"
              htmlHeight={594}
              htmlWidth={582}
              src={`${getAppPublicPath()}/footer-right.png`}
            />
          </LazyLoad>
        </>
      )}
      <Box bg="footerColor" flex={1} paddingBottom={12}>
        <Container>
          <Flex
            justifyContent="center"
            alignItems="center"
            direction={{
              base: 'column',
              md: 'row',
            }}
          >
            <Box
              mr={{
                base: 0,
                md: 12,
              }}
              my={{
                base: 4,
                md: 0,
              }}
            >
              <HeaderLogo as="div" maxWidth="250px" mx="auto" />
            </Box>
            <Flex flexDirection="column">
              <Box>
                {menuMain && (
                  <List spacing={8}>
                    {menuMain.items.map((item) => (
                      <ListItem key={`${item.title}-${item.url}`}>
                        <Box
                          fontSize="lg"
                          fontWeight="bold"
                          textTransform="uppercase"
                        >
                          {renderLink(item)}
                        </Box>
                        {item.children.length > 0 && (
                          <Stack
                            direction={{
                              base: 'column',
                              md: 'row',
                            }}
                            as="ul"
                            spacing={4}
                            listStyleType="none"
                          >
                            {item.children.map((child) => (
                              <ListItem key={`${child.title}-${child.url}`}>
                                <Box>{renderLink(child)}</Box>
                              </ListItem>
                            ))}
                          </Stack>
                        )}
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
              <Flex
                mt={menuMain?.items?.length > 0 ? 12 : 0}
                direction={{
                  base: 'column',
                  md: 'row',
                }}
              >
                <Box>
                  © {format(new Date(), 'yyyy')} TVV Touristik-Verlag GmbH
                </Box>
                <Box
                  marginX={8}
                  display={{
                    base: 'none',
                    md: 'block',
                  }}
                >
                  |
                </Box>
                <Box
                  mt={{
                    base: 8,
                    md: 0,
                  }}
                >
                  <Stack
                    direction={{
                      base: 'column',
                      md: 'row',
                    }}
                    as="ul"
                    spacing={4}
                  >
                    {menu && menu.items
                      ? menu.items.map(
                          (item) =>
                            item && (
                              <Box
                                key={`${menu.name}_${item.title}`}
                                as="li"
                                display="inline-block"
                              >
                                <NextLink href={item.url} passHref>
                                  <a>{item.title}</a>
                                </NextLink>
                              </Box>
                            )
                        )
                      : null}

                    {appConfig.config?.cookieScriptID && (
                      <Box cursor="pointer" onClick={openCookieSettings}>
                        Cookie-Einstellungen
                      </Box>
                    )}
                  </Stack>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Flex>
  );
};

export default React.memo(EbikeAtlasFooter);
